* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
}

body {
  font-family: Ubuntu;
  background-color: var(--background);
  color: white;
  --background: rgb(24, 24, 24);
  font-size: 16px;
  --border: rgba(247, 247, 247, 0.3);
}

/*alt background color*/
/* 
body{
  font-family: Ubuntu;
  background-color: var(--background);
  color: black;
  --background: rgba(132,159,141,.01);
  font-size: 20px;
  --border: rgba(247,247,247,0.3);
} */

#root {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*----------HTML TAGS----------*/
h1 {
  margin-bottom: 32px;
  font-weight: normal;
}

h2 {
  margin-bottom: 24px;
  font-weight: normal;
}

h3 {
  font-weight: normal;
  margin-bottom: 20px;
}

main {
  padding: 0 15px;
  flex-grow: 1;
  max-width: 1024px;
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
}

img {
  width: 100%;
  height: auto;
}

footer {
  background-color: var(--background);
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  justify-content: space-between;
  border-top: 1px solid gray;
  width: 100%;
  margin-top: 15px;
}

hr {
  height: 1px;
  margin: 100px 0;
  border-color: var(--border);
}

/*----------CLASSES----------*/
.home {
  align-content: center;
}

.about-text {
  font-size: 18px;
}

.about-text p + * {
  margin-top: 1.2em;
}

.dev-text p + * {
  margin-top: 1.2em;
}

.no-underline {
  text-decoration: none;
}

.home-link-blue,
.home-link-purple,
.home-link-green {
  transition: all 0.3s ease;
}

.home-link-blue:hover {
  text-shadow: 0 0 10px rgb(0, 133, 177), 0 0 20px rgb(0, 133, 177);
}

.home-link-purple:hover {
  text-shadow: 0 0 10px rgb(172, 0, 172), 0 0 20px rgb(172, 0, 172);
}

.home-link-green:hover {
  text-shadow: 0 0 10px rgb(0, 192, 96), 0 0 20px rgb(0, 192, 96);
}

.blue {
  color: deepskyblue;
  text-decoration: none;
}

.purple {
  color: magenta;
  text-decoration: none;
}

.green {
  color: springgreen;
  text-decoration: none;
}

.pink {
  color: hotpink;
  text-decoration: none;
}

.red {
  color: red;
  text-decoration: none;
}

.img-border {
  border: 1px solid var(--border);
}

.fade-in {
  animation: 1s linear fadeInAnimation;
}

.dev-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: max-content;
  gap: 40px;
}

.github {
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
}

.gittext {
  max-width: 100px;
}

.gitcat {
  max-width: 100px;
}

@keyframes rotate-bounce {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg); /* Rotate clockwise */
  }
  50% {
    transform: rotate(-10deg); /* Rotate counterclockwise */
  }
  100% {
    transform: rotate(0deg);
  }
}
/* Hovering over github container only rotates gitcat image */
.github:hover .gitcat {
  animation: rotate-bounce 0.3s ease-in-out;
}

.vertical-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: max-content;
  gap: 40px;
}

.home-cards {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.home-card {
  width: 100%;
  height: 200px;
  background-color: darkcyan;
}

.home-pic {
  margin-top: -15px;
}

.about-flip {
  display: flex;
  flex-direction: column-reverse;
}

.icon {
  height: 20px;
  width: 20px;
  vertical-align: bottom;
}

.youtube {
  aspect-ratio: 16/9;
}

/*----------Upcoming Shows----------*/
.show {
  margin-bottom: 20px;
  border-bottom: 1px dotted gray;
}

.time {
  font-style: italic;
}

.summary {
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  margin: 2px 0;
}

/*---------------------------------*/
.project-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 1px 0;
  width: 100vw;
  margin-left: -15px;
  margin-right: -15px;
}

.project-card {
  padding: 10px;
}

.project-card-img {
  margin-bottom: 5px;
}

.project-card-img:hover {
  transition: all 0.25s ease;
  opacity: 0.8;
}

.project-card-text {
  margin-top: 5px;
  font-size: 14px;
}

.dev-features {
  margin-left: 15px;
}

.dev-info-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dev-info-text p + * {
  margin-top: 1.2em;
}

.mobile-full-width {
  margin-left: -15px;
  margin-right: -15px;
  width: 100vw;
}

.margin-top-med {
  margin-top: 100px;
}

.margin-bottom-med {
  margin-bottom: 100px;
}

.margin-bottom-small {
  margin-bottom: 50px;
}

.margin-bottom-zero {
  margin-bottom: 0;
}

.margin-top-small {
  margin-top: 50px;
}

.margin-bottom-small-mobile {
  margin-bottom: 50px;
}

/*----------Single div----------*/
.single-background {
  height: 300px;
  width: 300px;
  background-color: white;
}

.single {
  height: 20px;
  width: 50px;
  background-color: blue;
}

/*----------DESKTOP----------*/
@media (min-width: 768px) {
  .project-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: max-content;
    gap: 40px;
    margin-left: 0;
    margin-right: 0;
  }

  .project-card {
    border: 1px solid var(--border);
    cursor: pointer;
  }

  .project-card h3 {
    font-size: 20px;
  }

  .about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: center;
  }

  .home-pic,
  .comedy-pic,
  .music-pic,
  .dev-pic {
    width: 100%;
    mask-image: linear-gradient(
      to right,
      transparent 0%,
      black 1%,
      black 99%,
      transparent 100%
    );
  }

  .home-pic {
    margin-top: 0;
  }

  .floated {
    max-width: 40%;
    float: left;
    margin-right: 40px;
  }

  .mobile-full-width {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .margin-bottom-small-mobile {
    margin-bottom: 0;
  }

  .recordings {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .track {
    margin: 0;
  }

  .contact {
    text-align: center;
  }
}
